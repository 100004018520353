<template>
  <div
    class="page-container w-screen h-vh-9/10 relative flex flex-wrap justify-center"
  >
    <div
      class="w-screen flex justify-center items-center flex-wrap relative h-full"
    >
      
    
      <template v-for="(question, index) in questions">
        
        <template-form-date
          v-if="currentStep == ( index + 1 ) && question && question.form_type == 'date'"
          :formDetail="question"
          :required="question.is_mandatory"
          v-model="responsesValues[index]"
          @input="responsesValues[index]=$event"
          :key="index"
          @previous="currentStep--; changeResponse();"
          @next="currentStep++; changeResponse();"
        ></template-form-date>

        <template-form-input
          v-if="currentStep == ( index + 1 ) && question && question.form_type == 'input'"
          :formDetail="question"
          :required="question.is_mandatory"
          v-model="responsesValues[index]"
          @input="responsesValues[index]=$event"
          :key="index"
          @previous="currentStep--; changeResponse();"
          @next="currentStep++; changeResponse();"
        ></template-form-input>

        <template-form-option
          v-if="currentStep == ( index + 1 ) && question && question.form_type == 'option'"
          :formDetail="question"
          :required="question.is_mandatory"
          v-model="responsesValues[index]"
          @input="responsesValues[index]=$event"
          :key="index"
          @previous="currentStep--; changeResponse();"
          @next="currentStep++; changeResponse();"
        ></template-form-option>
        
        <template-form-select
          v-if="currentStep == ( index + 1 ) && question && question.form_type == 'select'"
          :formDetail="question"
          :required="question.is_mandatory"
          :api_resource="$APIEndpoint + question.api_resource"
          v-model="responsesValues[index]"
          @input="responsesValues[index]=$event"
          :key="index"
          @previous="currentStep--; changeResponse();"
          @next="currentStep++; changeResponse();"
        ></template-form-select>

        <!-- <template-form-gender
          v-if="currentStep == ( index + 1 ) && question && question.form_type == 'gender'"
          :formDetail="question"
          :required="question.is_mandatory"
          v-model="responsesValues[index]"
          @input="responsesValues[index]=$event"
          :key="index"
          @previous="currentStep--; changeResponse();"
          @next="currentStep++; changeResponse();"
        ></template-form-gender> -->
        
      </template>

    </div>
  </div>
</template>


<script>

  import TemplateFormDate from "../components/Evaluation/TemplateFormDate.vue";
  import TemplateFormInput from "../components/Evaluation/TemplateFormInput.vue";
  import TemplateFormOption from "../components/Evaluation/TemplateFormOption.vue";
  import TemplateFormSelect from "../components/Evaluation/TemplateFormSelect.vue";

  import { mapMutations, mapGetters } from 'vuex';
  export default {
    name: "InSearchPage",
    data:() => ({
      currentStep: 1,
      responsesValues: [],
      internalQuestions: [],
      questions: [],
      start: false
    }),
    components: {
      TemplateFormDate,
      TemplateFormInput,
      TemplateFormOption,
      TemplateFormSelect
    },
    computed: {
      ...mapGetters(["productCategories"]),
      lastSteep() {
        return this.questions.length + 1
      },
      userResponses() {
        let self = this;
        return this.responsesValues.map(function(value, index) {
          return {
            question_id: self.questions[index].id,
            value: value
          };
        })
      },
      products_requirements_for_to_sugestion() {
        return this.$store.state.BuyProcessStore.products_requirements_for_to_sugestion;
      }
    },
    mounted() {
      let self = this;
      this.currentStep = 1;
      this.$store.commit("updateBgApp", "");
      this.$store.dispatch("getProductsRequirements", {filters: {is_for_to_suggestion:true}}).then((response) => {
        self.questions = response;
      })
    },
    destroyed() {},
    methods: {
      ...mapMutations(["updateSelectedProductCategories"]),
      changeResponse() {
        let self = this;
        if (this.currentStep == 0) {
          self.$router.push({ name:'Inicio', params: {} })
        } else if (this.currentStep == this.lastSteep) {
          this.$store.dispatch("getProductCategories", {filters:{calculate_from_evaluation: JSON.stringify(this.userResponses)}}).then((response) => {
            // console.log("Calculate categories response");
            // console.log(response);
            this.productCategories.setUserSelection(response);
            self.$router.push({ name:'our-services', params: {} });
          })
        } else {
          this.$emit("change-responses", {
            responses: this.userResponses
          });
          return false;
        }
      }
    }
  }
</script>